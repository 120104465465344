
import Vue from 'vue';

export default Vue.extend({
  data: (): {
    sortList: boolean;
  } => ({
    sortList: true,
  }),
  props: ['property', 'account', 'selectedGA'],
  methods: {
    isPropertyChecked(propertyId: string): boolean {
      if (this.selectedGA?.propertyId) {
        return this.selectedGA.propertyId === propertyId;
      } else return false;
    },
    propertyCheckedClass(propertyId: string): string {
      return this.isPropertyChecked(propertyId) ? 'green--text' : 'grey--text text--lighten-2';
    },
    selectView(accountId: string, propertyId: string /*, viewId: number*/): void {
      this.sortList = false;
      this.$emit('select-view', accountId, propertyId);
    },
  },
});
